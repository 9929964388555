import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import Layout from "components/Layout";
import Link from "components/Link";
import { useTheme, transitionString } from "components/Theming";
import Container from "components/Container";
import { rhythm } from "../lib/typography";
import { bpMaxMD } from "../lib/breakpoints";

const Hero = (props) => {
  const theme = useTheme();
  return (
    <section
      css={css`
        color: ${theme.colors.white};
        width: 100%;
        background: ${theme.colors.headerBg};
        transition: background-color ${transitionString};
        padding: 0px 0 30px 0;
        display: flex;

        *,
        h1,
        h2,
        h3 {
          color: ${theme.colors.white};
        }

        ${bpMaxMD} {
          padding-bottom: 10px;
        }
      `}
    >
      <Container
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {props.title && <h1>Hi, I'm Jacek Galanciak</h1>}
        <p
          css={css`
            color: ${theme.colors.white};
            position: relative;
            z-index: 5;
            font-weight: 300;
            line-height: 1.5;
            margin: 0;
            //max-width: ${rhythm(15)};
          `}
        >
          {props.children}
        </p>
      </Container>
    </section>
  );
};

export default function Index({ data: { site, allMdx } }) {
  const theme = useTheme();
  return (
    <Layout site={site}>
      <Hero title="Hi, I'm Jacek Galanciak">
        I’m a Ruby gangster, JavaScript thug, software architecture hooligan and
        microservices mobster. Just because the industry keeps using silly words
        like ninja and rockstar, it doesn’t mean I can’t outdumb that.{" "}
      </Hero>
      <Container
        css={css`
          padding-bottom: 0;
          padding-top: 40px;
        `}
      >
        <p>
          I'm a software engineer with a strong focus on implementing
          maintainable systems through modular design and clean architecture.
          With over 15 years of experience in software development, I had an
          opportunity to tackle complex problems and gain experience in the
          following:
        </p>
        <ul>
          <li>
            <strong>microservices</strong> and asynchronous communication in an{" "}
            <strong>event-driven architecture</strong> (via RabbitMQ or other
            message queues)
          </li>
          <li>
            <strong>domain-driven design</strong> in both tactical patterns and
            business domain mapping
          </li>
          <li>
            mastery in <strong>Ruby</strong> and <strong>Rails</strong> –
            started in 2007 with Ruby 1.8.5 and Rails 1.2
          </li>
          <li>
            <strong>domain knowledge</strong> in <strong>staffing</strong>,{" "}
            <strong>fintech</strong>, <strong>international payments</strong>,
            e-learning, and real estates
          </li>
          <li>
            <strong>remote work</strong>: I'm based in Łódź/Poland and worked
            exclusively remotely for more than 12 years with companies from
            Europe, Asia and North America
          </li>
        </ul>
        <p>
          My primary way of giving back to the programming community is through
          my blog, but I also have some interesting <strong>open-source</strong>{" "}
          work:
        </p>
        <ol>
          <li>
            <a
              href="https://razorjack.net/quicksand/"
              target="_blank"
              rel="noopener noreferrer"
            >
              quicksand.js
            </a>{" "}
            : 1.9k stars and 250 forks on GitHub. Create a delightful animation
            when sorting/filtering your collections
          </li>
          <li>
            <a
              href="https://github.com/razorjack/s3-directory-listing"
              target="_blank"
              rel="noopener noreferrer"
            >
              s3-directory-listing
            </a>
            : use AWS Lambda functions to generate index.html with a clean
            directory listing whenever you change anything in your S3 bucket
          </li>
          <li>
            <a
              href="https://github.com/razorjack/dotfiles"
              target="_blank"
              rel="noopener noreferrer"
            >
              dotfiles
            </a>
            : Not an open-source project per se but contains my current tools
            and configurations that took many years to accumulate. Want to learn
            about a craftsman? Have a look around their workshop!
          </li>
        </ol>
        <p>
          I'm currently a <strong>lead engineer</strong> at{" "}
          <a href="https://pilot.co" target="_blank" rel="noopener noreferrer">
            Pilot
          </a>
          , a{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://techcrunch.com/2017/02/10/yc-backed-pilot-is-building-a-contractor-platform-geared-for-the-future-of-work/"
          >
            Y Combinator-backed
          </a>{" "}
          startup where we create a more open world-wide job market.
        </p>
      </Container>
      <Hero>
        <h3>Latest blog posts</h3>

        {allMdx.edges.map(({ node: post }) => (
          <div
            key={post.id}
            css={css`
              font-size: 0.875rem;
              margin-bottom: 5px;
              a {
                text-decoration: underline;
              }
              a:hover {
                color: ${theme.colors.white};
                text-shadow: 0px 0px 1px ${theme.colors.white};
              }
            `}
          >
            <div>
              <Link
                to={`/${post.frontmatter.slug}`}
                aria-label={`View ${post.frontmatter.title}`}
              >
                {post.frontmatter.title}
              </Link>
              <span
                css={css`
                  margin-left: 8px;
                  font-size: 0.6rem;
                  color: ${theme.colors.gray};
                `}
              >
                {post.frontmatter.date}
              </span>
            </div>
          </div>
        ))}

        <Link
          to="/blog"
          aria-label="Visit blog page"
          css={css`
            font-size: 0.75rem;
            &:hover {
              color: ${theme.colors.white};
            }
          `}
        >
          View all articles →
        </Link>
      </Hero>
      <Container>
        Feel free to contact me at{" "}
        <a href="mailto: jacek.galanciak@gmail.com">
          jacek.galanciak@gmail.com
        </a>
        . You can also view my <Link to="/resume">résumé</Link>. I'm careful
        about your data, see <Link to="/privacy">Privacy Policy</Link>.
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { published: { ne: false }, static: { ne: true } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "D MMM YYYY")
            description
            #            banner {
            #              childImageSharp {
            #                sizes(maxWidth: 720) {
            #                  ...GatsbyImageSharpSizes
            #                }
            #              }
            #            }
            slug
            keywords
          }
        }
      }
    }
  }
`;
